import { IMAGE_BASE_URL, IMAGE_LIVE_URL } from './config-global';

function startsWithPrefix(str, prefix = "temp/") {
    // Regular expression to check if the string is a full URL
    const urlPattern = /^https?:\/\/|^www\./;

    // If str is a full URL, return it as-is
    if (urlPattern.test(str)) {
        return str;
    }

    if (str.startsWith("blob:")) {
        return str;
    }

    // Check if the string represents a local image in the public folder
    if (str?.startsWith("public/") || str?.startsWith("/public/")) {
        return str.replace(/^public\//, "/"); // Remove 'public/' prefix
    }

    // Append the base URL based on the prefix
    const url = str?.startsWith(prefix) ? `${IMAGE_BASE_URL}${str}` : `${IMAGE_LIVE_URL}${str}`;
    return url;
}

export default startsWithPrefix;
