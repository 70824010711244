'use client'

import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from '@headlessui/react'
import {
    Bars3Icon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { Button } from '@/components/ui/button';
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon, RectangleGroupIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import startsWithPrefix from '@/utils/pathUtils';
import ServicesWithCTA from './services-with-cta';
import { useRouter } from "next/navigation";
import { usePathname } from 'next/navigation';
import { useLayoutContext } from '../../../useLayout';
import useRouterWithLanguage from "@/utils/useRouterWithLanguage";
import languageData from "@/data/languages.json";

export default function Example({ isFixed, subHeader = false }: { isFixed?: any, subHeader?: boolean }) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [openStates, setOpenStates] = useState<any>({});
    const [popoverOpen, setPopoverOpen] = useState(true);  // Track popover open state
    const [isVisible, setIsVisible] = useState(true); // Track visibility of the div
    const divRef = useRef(null); // Reference to the div you want to check
    const popoverRef = useRef<HTMLButtonElement | null>(null);
    const router = useRouterWithLanguage();
    const router_1 = useRouter();

    const { lng } = useLayoutContext();

    const data = require(`@/data/${lng}/header/menuItems.json`);
    const products = require(`@/data/${lng}/header/products.json`);

    const handleRouter = (path: string | undefined) => {
        if (path) {
            router.push(path);
        } else {
            console.error("Invalid path:", path);
        }
    };

    useEffect(() => {
        // Create an IntersectionObserver to observe the visibility of the div
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Check if the div is in the viewport (visible)
                // Always set popoverOpen to true when the div becomes visible
                if (entry.isIntersecting) {
                    setPopoverOpen(true); // Set popoverOpen to true
                }
                setIsVisible(entry.isIntersecting); // Set state to true/false based on visibility
            },
            {
                root: null, // Observing within the viewport
                threshold: 0.1, // 10% of the div needs to be in the viewport to be considered "visible"
            }
        );

        // Observe the target div if it's available
        if (divRef.current) {
            observer.observe(divRef.current);
        }

        const handleClickOutside = (event: any) => {
            if (popoverRef.current && !popoverRef.current?.contains(event.target)) {
                // If click is outside popover, set popoverOpen to true
                setPopoverOpen(true);
            }
        };

        // Add the event listener on mount
        document.addEventListener("click", handleClickOutside);

        // Cleanup the observer when the component is unmounted
        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const toggleDisclosure = (name: any) => {
        setOpenStates((prev: any) => ({
            ...prev,
            [name]: !prev[name],
        }));
    };

    const handlePopoverChange = (open: boolean) => {
        setPopoverOpen(open); // Set popover open state
    };

    const languages = languageData?.languages;

    const pathname = usePathname();
    const currentLang = pathname[1] + pathname[2];

    function changeLanguage(url: string, newLang: string) {
        const languageValues = languages.map((lang: any) => lang.value).join('|');
        const regex = new RegExp(`^/(${languageValues})`);
        const updatedUrl = url.replace(regex, '');

        router_1.push(`/${newLang}${updatedUrl}`);
    }

    return (
        <>
            {isVisible &&
                <header
                    ref={popoverRef}
                    className={`isolate z-20 bg-bc-gray border-b border-line-gray ${subHeader && !popoverOpen ? "relative" : "sticky"} top-0`}
                >
                    <nav aria-label="Global" className="mx-auto flex items-center justify-between px-4 lg:px-8 max-h-14 ">
                        <div className="flex lg:flex-1">
                            <div
                                onClick={() => handleRouter("/home")}
                            >
                                <a className="-m-1.5 p-1.5 cursor-pointer">
                                    <img
                                        alt=""
                                        src={`${startsWithPrefix("Home_Page_Images/fuzionest logo_full_black.svg")}`}
                                        className="h-auto w-auto"
                                    />
                                </a>
                            </div>
                            <div className="hidden lg:grid grid-cols-2 w-full ">
                                <div className="flex items-center w-fit max-h-[4.4rem] pt-4 justify-end h-full border-r border-line-gray pr-4">
                                    <PopoverGroup className="hidden xl:flex lg:gap-x-12 h-full py-0 items-center justify-center">
                                        <Popover className="relative z-10">
                                            {({ open, close }) => (
                                                <>
                                                    <PopoverButton
                                                        className="focus-visible:outline-none flex items-center gap-x-1 text-lg font-normal text-gray-900 tracking-wide p-6"
                                                        onClick={() => handlePopoverChange(open)}
                                                    // Set popover open state
                                                    >
                                                        <img width={"36px"}
                                                            src={`${startsWithPrefix("header/globe_icon.png")}`} />
                                                    </PopoverButton>

                                                    <PopoverPanel
                                                        className="absolute w-[130px] left-[-30px] right-0 top-[70px] z-50 bg-transparent shadow-lg ring-1 ring-gray-900/5 data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                                                    >

                                                        <div className='bg-bc-gray'>
                                                            <div className="flex lg:flex-col items-center">
                                                                {languages.map((item: any) => (
                                                                    <div key={item.name} className="group relative rounded-lg py-2 text-sm/6 cursor-pointer">
                                                                        <h3
                                                                            onClick={() => {
                                                                                changeLanguage(pathname, item.value);
                                                                            }}
                                                                            className={`mt-4 block text-base font-normal font-reckless ${currentLang === item.value ? "text-orange" : "text-gray-900"}`}>
                                                                            {item.name}
                                                                        </h3>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                        </div>
                                                    </PopoverPanel>
                                                </>
                                            )}
                                        </Popover>


                                    </PopoverGroup>
                                </div>
                                <div className="flex block">

                                </div>
                            </div>
                        </div>
                        <div className="flex lg:!hidden">
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon aria-hidden="true" className="size-6" />
                            </button>
                        </div>
                        <PopoverGroup className="hidden lg:flex lg:gap-x-12 h-full py-0 items-center justify-center">
                            <Popover>
                                {({ open, close }) => (
                                    <>
                                        <PopoverButton
                                            className="focus-visible:outline-none flex items-center gap-x-1 text-lg font-normal text-gray-900 tracking-wide p-6"
                                            onClick={() => handlePopoverChange(open)} // Set popover open state
                                        >
                                            {data?.headings[0]}
                                            {open ? (
                                                <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-black transform rotate-180" />
                                            ) : (
                                                <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-black" />
                                            )}
                                        </PopoverButton>

                                        <PopoverPanel
                                            className="absolute inset-x-0 top-14 -z-10 bg-transparent shadow-lg ring-1 ring-gray-900/5 data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                                        >
                                            <div className='bg-bc-gray'>
                                                <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                                                    {products.map((item: any) => (
                                                        <div key={item.name} className="group relative rounded-lg p-6 text-sm/6">
                                                            <h3 className="mt-4 block text-base font-normal font-reckless text-maroon">
                                                                {item.name}
                                                            </h3>
                                                            <div className='grid grid-cols-2 gap-2 mt-4'>
                                                                {item?.options?.map((data: any, index: any) => (
                                                                    <p key={index} onClick={() => handleRouter(data.href)} className="mt-1 cursor-pointer underline text-lg underline-offset-4 font-light decoration-1 decoration-gray-500">{data?.label}</p>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="bg-bc-gray border-t border-line-gray">
                                                    <div className="mx-auto">
                                                        <div
                                                            onClick={() => handleRouter("/estimate-calculator")}
                                                        >
                                                            <a
                                                                className="cursor-pointer flex bg-darkblue hover:bg-maroon text-white items-center justify-center gap-x-8 p-3 text-lg font-light"
                                                            >
                                                                {data?.estimation}
                                                                <img
                                                                    src={`${startsWithPrefix("header/arrow_white.svg")}`}
                                                                    alt="Right Up Arrow"
                                                                    className='md:text-[1.0rem] font-[400] !tracking-[0.8px]' />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='h-screen border-t border-line-gray backdrop-blur-lg' onClick={() => close()} ></div>
                                        </PopoverPanel>
                                    </>
                                )}
                            </Popover>

                            <a onClick={() => handleRouter("/about")} className="cursor-pointer text-lg font-normal text-gray-900 p-6">
                                {data?.headings[1]}
                            </a>

                            <Popover>
                                {({ open, close }) => (
                                    <>
                                        <PopoverButton
                                            className="focus-visible:outline-none flex items-center gap-x-1 text-lg font-normal text-gray-900 tracking-wide p-6"
                                            onClick={() => handlePopoverChange(open)} // Set popover open state
                                        >
                                            {data?.headings[2]}
                                            {open ? (
                                                <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-black transform rotate-180" />
                                            ) : (
                                                <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-black" />
                                            )}
                                        </PopoverButton>

                                        <PopoverPanel
                                            className="absolute inset-x-0 top-14 -z-10 bg-transparent backdrop-blur-xl shadow-lg ring-1 ring-gray-900/5 data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                                        >

                                            <ServicesWithCTA data={data} handleRouter={handleRouter} />

                                            <div className='h-screen bg-transaparent' onClick={() => close()} ></div>
                                        </PopoverPanel>
                                    </>
                                )}
                            </Popover>


                            <a onClick={() => handleRouter("/contact-us")} className="cursor-pointer text-lg font-normal text-gray-900 bg-orange hover:bg-maroon hover:text-white px-6 p-[15px] h-full">
                                {data?.headings[3]}
                            </a>
                        </PopoverGroup>
                    </nav>
                    <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:!hidden">
                        <div className="fixed inset-0 z-10" />
                        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-bc-gray px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                            <div className="flex items-center justify-end">
                                <button
                                    type="button"
                                    onClick={() => setMobileMenuOpen(false)}
                                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XMarkIcon aria-hidden="true" className="size-6" />
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="flex flex-col space-y-2 py-6">
                                        <Disclosure as="div" className="-mx-3 border-b border-line-gray">
                                            <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-normal text-gray-900 hover:bg-gray-50">
                                                {data?.headings[0]}
                                                <ChevronDownIcon aria-hidden="true" className="size-5 flex-none group-data-[open]:rotate-180" />
                                            </DisclosureButton>
                                            <DisclosurePanel className="mt-2 space-y-2">
                                                {products.map((item: any) => (
                                                    <div key={item.name}>
                                                        <Disclosure
                                                            as="div"
                                                            className="mt-2"
                                                            onChange={() => toggleDisclosure(item.name)}
                                                        >
                                                            {({ open }) => (
                                                                <>
                                                                    <Disclosure.Button className="rounded-lg py-2 pl-6 pr-3 text-md/7 font-light text-gray-900 hover:bg-gray-50 w-full flex items-center justify-between">
                                                                        {item.name}
                                                                        {open ? (
                                                                            <ChevronDownIcon aria-hidden="true" className="size-5 flex-none" />
                                                                        ) : (
                                                                            <ChevronRightIcon aria-hidden="true" className="size-5 flex-none" />
                                                                        )}
                                                                    </Disclosure.Button>
                                                                    <Disclosure.Panel className="pl-6">
                                                                        {item.options?.map((option: any, index: any) => (
                                                                            <p
                                                                                onClick={() => handleRouter(option.href)}
                                                                                key={index}
                                                                                className="text-[16px] block rounded-lg py-1 pl-8 pr-3 text-gray-700 hover:bg-gray-100 underline"
                                                                            >
                                                                                {option.label}
                                                                            </p>
                                                                        ))}
                                                                    </Disclosure.Panel>
                                                                </>
                                                            )}
                                                        </Disclosure>
                                                    </div>
                                                ))}
                                                <div className="bg-bc-gray border-t border-line-gray">
                                                    <div className="mx-auto">
                                                        <div>
                                                            <a
                                                                onClick={() => handleRouter("/estimate-calculator")}

                                                                className="cursor-pointer flex bg-darkblue hover:bg-maroon text-white items-center justify-center gap-x-8 p-2 text-xs font-light"
                                                            >
                                                                {data?.estimation}

                                                                <img
                                                                    src={`${startsWithPrefix("header/arrow_white.svg")}`}
                                                                    alt="Right Up Arrow" className='md:text-[1.0rem] font-[400] !tracking-[0.8px] w-6 h-6' />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </DisclosurePanel>
                                        </Disclosure>
                                        <a
                                            onClick={() => handleRouter("/about")}
                                            className="-mx-3 cursor-pointer border-b border-line-gray block rounded-lg px-3 py-2 text-base/7 font-normal text-gray-900 hover:bg-gray-50"
                                        >
                                            {data?.headings[1]}
                                        </a>
                                        <Disclosure as="div" className="-mx-3 border-b border-line-gray">
                                            <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-normal text-gray-900 hover:bg-gray-50">
                                                {data?.headings[2]}
                                                <ChevronDownIcon aria-hidden="true" className="size-5 flex-none group-data-[open]:rotate-180" />
                                            </DisclosureButton>
                                            <DisclosurePanel className="mt-2 space-y-2">
                                                {data.menuItems.map((item: any) => (
                                                    <div key={item.label}>
                                                        <Disclosure
                                                            as="div"
                                                            className="mt-2"
                                                            onChange={() => toggleDisclosure(item.label)}
                                                        >
                                                            {({ open }) => (
                                                                <>
                                                                    <Disclosure.Button
                                                                        onClick={
                                                                            () => handleRouter(item?.href)
                                                                        }
                                                                        className="rounded-lg py-2 pl-6 pr-3 text-md/7 font-light text-gray-900 hover:bg-gray-50 w-full flex items-center justify-between">
                                                                        <p className="text-[16px]" >{item.label}</p>
                                                                        {/* {open ? (
                                                                            <ChevronDownIcon aria-hidden="true" className="size-5 flex-none" />
                                                                        ) : (
                                                                            <ChevronRightIcon aria-hidden="true" className="size-5 flex-none" />
                                                                        )} */}
                                                                    </Disclosure.Button>
                                                                    {/* <Disclosure.Panel className="pl-6">
                                                                        {item.options?.map((option, index) => (
                                                                            <a
                                                                                key={index}
                                                                                href={option.href}
                                                                                className="block rounded-lg py-1 pl-8 pr-3 text-sm text-gray-700 hover:bg-gray-100 underline"
                                                                            >
                                                                                {option.label}
                                                                            </a>
                                                                        ))}
                                                                    </Disclosure.Panel> */}
                                                                </>
                                                            )}
                                                        </Disclosure>
                                                    </div>
                                                ))}
                                                <div className="-mx-6 relative text-white px-4 py-4 overflow-hidden group">
                                                    <div
                                                        className="absolute inset-0 bg-cover bg-center transition-transform duration-500 group-hover:scale-110"
                                                        style={{
                                                            backgroundImage: `url('${startsWithPrefix(data.cta.image)}')`
                                                        }}
                                                    />
                                                    <div className='flex flex-col space-y-2'>
                                                        <div className="w-full flex flex-row items-center justtify-between text-white text-sm xl:text-xl p-4 md:pl-6 md:pr-12 font-normal z-10 group-hover:underline decoration-2 underline-offset-2">
                                                            <a
                                                                onClick={() => handleRouter("/estimate-calculator")}
                                                                className='cursor-pointer bg-blue-600 text-white px-3 py-2 rounded-[4px] flex-2'
                                                            >
                                                                Cost Estimation
                                                            </a>
                                                            <img
                                                                className='ml-auto md:text-[1.0rem] font-[400] !tracking-[0.8px] justify-self-end'
                                                                src={`${startsWithPrefix("header/arrow_white.svg")}`}
                                                                alt="Right Up Arrow" />
                                                        </div>
                                                        <div className="text-white text-sm xl:text-xl p-4 font-normal z-10 group-hover:underline decoration-2 underline-offset-2">
                                                            {data.cta.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            </DisclosurePanel>
                                        </Disclosure>
                                        <div className='-mx-6 border-b border-line-gray absolute bottom-0 w-full'>
                                            <a
                                                onClick={() => handleRouter("/contact-us")}
                                                className="cursor-pointer block rounded-lg px-3 py-2 text-base/8 font-light bg-orange text-white hover:bg-maroon"
                                            >
                                                {data?.headings[3]}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </Dialog>
                </header>
            }
            <div ref={divRef}></div>
            {subHeader && popoverOpen && !mobileMenuOpen && (
                <header
                    className={`isolate z-20 bg-bc-gray border-b border-line-gray sticky top-0 min-h-10`}
                >
                    <nav
                        aria-label="Global"
                        className="mx-auto flex items-center justify-between px-4 lg:px-8"
                    >
                        <div className="flex items-center">
                            <a onClick={() => handleRouter(data.mainLink.href)} className="cursor-pointer text-lg font-semibold tracking-wide">
                                {data.mainLink.label}
                            </a>
                        </div>
                        <div className="flex lg:!hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Open main menu</span>
                                <ChevronDownIcon aria-hidden="true" className="size-6" />
                            </button>
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">
                            {data.subLinks.map((link: any, index: number) => (
                                <a
                                    key={index}
                                    onClick={() => handleRouter(link.href)}
                                    className={`cursor-pointer text-lg font-normal text-gray-900 p-6 ${link.isHighlighted ? "bg-orange hover:bg-maroon hover:text-white" : ""
                                        }`}
                                >
                                    {link.label}
                                </a>
                            ))}
                        </div>
                    </nav>
                </header>
            )}
        </>
    );
}
