import startsWithPrefix from "@/utils/pathUtils";
import React from "react";

const ServicesWithCTA = ({ data, handleRouter }: any) => {
    return (
        <div className="h-full items-center justify-end">
            <div className="w-[70%] justify-self-end flex flex-row flex-wrap gap-4 bg-gray-200 border-b border-l border-line-gray">
                {/* Left Menu Section */}
                <div className="flex-1 flex flex-col p-12 space-y-8">
                    {data.menuItems.map((item: any, index: number) => (
                        <a
                            key={index}
                            onClick={() => handleRouter(item.href)}
                            className="cursor-pointer text-lg md:text-3xl font-normal text-gray-800 hover:underline cursor-pointer"
                        >
                            {item.label}
                        </a>
                    ))}
                </div>

                {/* Right CTA Section */}
                <div className="flex-1 relative text-white p-8 overflow-hidden group">
                    <div
                        className="absolute inset-0 bg-cover bg-center transition-transform duration-500 group-hover:scale-110"
                        style={{
                            backgroundImage: `url('${startsWithPrefix(data.cta.image)}')`
                        }}
                    />
                    <div
                        onClick={() => handleRouter(data.cta.href)}
                        className="flex cursor-pointer">

                        <div className="absolute top-4 md:top-10 left-4 md:left-10 bg-blue-600 text-white px-3 py-1 rounded-md text-sm md:text-lg">
                            {data.cta.label}
                        </div>
                        <div className="absolute top-4 md:top-10 right-4 text-2xl">
                            <span>&#8599;</span>
                        </div>
                    </div>

                    <div className="absolute bottom-0 xl:bottom-8 left-4 text-sm xl:text-xl p-4 md:pl-6 md:pr-12 font-normal z-10 group-hover:underline decoration-2 underline-offset-2">
                        {data.cta.description}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesWithCTA;
