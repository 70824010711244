import { useRouter } from 'next/navigation';
import { useParams } from 'next/navigation';

const useRouterWithLanguage = () => {
  const router = useRouter();
  const params = useParams(); // Access params from the current dynamic route

  // Wrapper for router.push to prepend language
  const pushWithLanguage = (path: any, options = {}) => {
    // Access dynamic 'lng' parameter
    const lng = params.lng || process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE; // Default to 'en' if 'lng' is not available in params
    const localizedPath = `/${lng}${path.startsWith('/') ? path : `/${path}`}`;
    return router.push(localizedPath, options); // Pass options if needed
  };

  // Return the router with overridden push
  return {
    ...router,
    push: pushWithLanguage, // Override the push method to handle language prefix
  };
};

export default useRouterWithLanguage;
